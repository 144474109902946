import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import { graphql } from 'gatsby'
import PerksModuleAccoglienza from "../components/PerksModule/PerksModuleAccoglienza"

import Perk from "../components/PerksModule/Perk"
import { useInView } from 'react-intersection-observer'
import { useSliderAttivita } from "../hooks/useSliderAttivita"
import BasicTextGalleryModule from "../components/BasicTextGalleryModule/BasicTextGalleryModule"


export default function AccoglienzaPage({ data }) {

  const slider_attivita = useSliderAttivita()
  const camelize = (str) =>  {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }


 


  const titles = ["Accoglienza", "Accoglienza"]
  const subtitles = ["Adatto a famiglie e piccoli gruppi", "Adatto a famiglie e piccoli gruppi"]
  const linkTexts = ["scopri di più", "approfondisci"]
  const linkUrls =["#accoglienza","#accoglienza"]


  return (
    <>
      <Seo title="Accoglienza" />
      <Layout>
        {<BannerModuleWrapper
          max={1}
          titles={titles}
          subtitles={subtitles}
          linkTexts={linkTexts}
          images={data.allWp.edges[0].node.accoglienzaSettings.backgrounds_accoglienza.bgsAccoglienza}
          linkUrls={linkUrls}
        />}



        <PerksModuleAccoglienza >
          <Perk title="The Title" content="The content"  />
        </PerksModuleAccoglienza>

       {/*  <BasicTextGalleryModule
          titolo={data.allWp.edges[0].node.attivitaSettings.attivita_contenuti.titoloSezioneAttivita}
          content={data.allWp.edges[0].node.attivitaSettings.attivita_contenuti.testiAttivita}
          images={slider_attivita[0].galleria}
        /> */}

        {data.allWp.edges[0].node.accoglienzaSettings.accoglienza_contenuti.testiAccoglienza.map((item, index) => (
          <BasicTextGalleryModule
            id={camelize(item.titolo)}
            key={index}
            titolo={item.titolo}
            content={item.paragrafo}
            images={item.galleria}
          />
        ))}

        
      </Layout>
    </>
  )
}



export const imagesWp = graphql`
{
  allWp {
    edges {
      node {
        accoglienzaSettings {
                accoglienza_contenuti {
                    testiAccoglienza {
              paragrafo
              titolo
              galleria {
                localFile {
                  children {
                    ... on ImageSharp {
                      id
                      resize(height: 168, width: 300, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                  childrenImageSharp {
                    original {
                      src
                      width
                      height
                    }
                    resize(height: 168, width: 300) {
                      src
                    }
                  }
                }
              }
            }
            
          }
          backgrounds_accoglienza  {
            bgsAccoglienza {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.7
                    placeholder: BLURRED
                    formats: AUTO
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`